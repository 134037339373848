import React, { useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
} from "@mui/material";
import { LoginHeader } from "../../components/Header";
import PricingBanner from "../../assets/images/PricingBanner.png";
import Herosection from "../../components/HeroSection/HeroSection";
import PlanCard from "../../components/PlanCard/planCard";
import { getPlans } from "../../apollo/server";
import { gql, useQuery } from "@apollo/client";
import Footer from "../../components/HomeScreen/Footer";
import CommissionSection from "../../components/ComissionSection/ComissionSection";
import useStyle from "./styles";
import ReusableFAQs from "../../components/ReUsableFAQs/ReUsableFAQs";
import ReusableStepper from "../../components/ReusableStepper/ReusableStepper";
import ap from "../../../src/assets/images/ap.png";
import rectangle from "../../../src/assets/images/rectangle.png";
import fineprint from "../../../src/assets/images/fneprint.png";
import UserContext from "../../context/User";
import Header from "../../components/Header/Header";

const GETPLANS = gql`
  ${getPlans}
`;

function PricingPage() {
  const { data, loading, error } = useQuery(GETPLANS);
  const { isLoggedIn } = useContext(UserContext);

  const classes = useStyle();
  const frequentlyAskQuestions = [
    {
      title: "How do marketplace commission fees work?",
      description: `<p> When you make a sale on the Hedge marketplace, you'll encounter a straightforward commission fee structure. </p><br/>
<p>Either a fixed fee per item sold or a percentage of the total purchase amount per transaction.</p><br/>
<p>DIY Plan Example: A customer places an order for 10m2 of turf</p></br>
<p><Strong>Marketplace Fee:</Strong> $0.99c per item</p>
<Strong>Total Fee Payable:</Strong> 10 x 0.99c = $9.90 + sales tax`,
    },
    {
      title: "How do delivery commission fees work?",
      description: ` <p>In addition to marketplace commission fees Hedge applies either a percentage or fixed fee per delivery. </p><br/>
<p>DIY Plan Example: Delivery by your driver </p><br/>
<p><Strong>Your Local Delivery Fee:</Strong> $38.00</p>
<p><Strong>Hedge delivery commission:</Strong> 15%</p>
<p><Strong>Total Fee Payable to Hedge:</Strong> $5.70 + tax</p><br/>
*Please note: Even if you offer FREE DELIVERY, Hedge will still apply a $5.50 minimum 
commission fee for delivery. It's essential to bear this in mind when implementing 
free delivery to your customers. store.`,
    },
    {
      title: "Which subscription plan is right for me?",
      description: ` <p>We provide three distinct seller plans, ensuring you can select the one that best 
suits your business needs. </p><br/>
<p>Most sellers opt for our DIFM plan, which stands for "Do-It-For-Me," as it offers the 
convenience of Hedge building and overseeing your online store on your behalf. This 
frees you to concentrate on what truly matters: nurturing your customer relationships 
and growing your business.</p><br/>
<p>Alternatively, if you prefer to manage your online store independently, our DIY plan, 
or "Do-It-Yourself" plan, is an excellent choice. It's entirely free to begin, and you 
only incur charges when you make a sale</p></br>
<p>Our Enterprise plan is for growers, wholesale suppliers, distributors and brands 
looking to sell direct to consumers on the Hedge marketplace. </p><br/>
<p>Want to clear some overstocked lines? Launch new products? Do it in minutes on 
Hedgemart our online clearance outlet where consumers are searching for deals that 
you have to offer. </p><br/>
<p>*Note: All plans come with a minimum 12-month contract term. Should you have a 
change of heart, just inform us, and we'll accommodate your needs accordingly.</p>`,
    },
    {
      title: "What’s shopfront?",
      description: ` Well, help you add a unique button to your websites homepage so customers can 
choose between pickup or delivery easily.`,
    },
    {
      title: "How does pick up work?",
      description: ` <p>As you become familiar with just how easy Hedge is to use, you’ll find some really 
great features that will ensure you’ll never miss an order again. </p><br/>
<p>Customers can easily choose to pick up their purchase instore during checkout, you’ll 
be able to manage these settings in your dashboard and set the time that you need 
to prepare the customer’s order for pickup.</p> `,
    },
  ];

  React.useEffect(() => {
    if (!loading && !error && data) {
      console.log("Plans Data:", data);
    }
  }, [loading, error, data]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Box
        sx={{ position: "relative", overflow: "hidden", alignItems: "center" }}
      >
        <Herosection
          imageSrc={PricingBanner}
          headingText="Pricing that's simple"
          descriptionText="Built for businesses of all sizes"
        />
      </Box>
      {/* <Card
        sx={{
          position: "absolute",
          right: "10%",
          top: "25rem",
          transform: "translateY(-50%)",
          width: "430px",
          borderRadius: "8px",
          zIndex: 10,
          display: {
            xs: "none", // Hide on extra-small screens
            sm: "none", // Hide on small screens
            md: "block", // Show on medium and larger screens
          },
        }}
      >
        <CardContent>
          <Typography
            variant="body2"
            sx={{
              color: "#808080",
              fontSize: "0.775rem",
              lineHeight: "2.5em",
              "& p": {
                marginBottom: "0.3em",
              },
            }}
          >
            <p>Take control of your online business.</p>
            <p>
              Select your plan and start selling the same day, equipped with
              your own branded online store and a delivery solution that
              customers will love.
            </p>
            <p>
              With features like 24/7 ordering and real-time delivery tracking
              you'll never miss an order again.
            </p>
          </Typography>
        </CardContent>
      </Card> */}
      <Box sx={{ textAlign: "center", marginTop: "70px" }}>
        <Typography
          variant="h5"
          sx={{ color: "black", fontWeight: "bold", marginBottom: "10px" }}
        >
          Start using Hedge for free
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "grey", fontSize: "1rem", marginBottom: "40px" }}
        >
          Reach more customers, in more neighborhoods with delivery or pickup
          today.
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "0",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Grid container spacing={3.5}> */}
        {/*  <Grid item xs={12} md={4}>
            <Box sx={{ padding: "0 20px" }}>
              <LeftCard />
            </Box>
          </Grid> */}
        <Grid item>
          <Box sx={{ padding: "0" }}>
            <Grid container spacing={2} className={classes.parentContainer}>
              {data?.getPlans.map((plan, index) => (
                <Grid item key={plan._id}>
                  <PlanCard data={plan} index={index} total={data.length} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* </Grid> */}
        </Grid>
      </Box>
      <ReusableStepper
        sectionTitle="Included in your plan"
        steps={[
          {
            title: "Online Store",
            description:
              "Create your free online store on the Hedge marketplace today and expand your reach to a broader customer base. With the Hedge mobile app, customers can effortlessly browse through your products and place orders with ease. Connect your trade customers to a seamless ordering and delivery experience with the ability to add their online purchases to their instore trade account.",
          },
          {
            title: "Delivery/Pick up",
            description:
              "With Hedge, you have the flexibility to offer both delivery and pick-up for your customers. Our platform includes cutting-edge features such as real-time driver tracking, optimised delivery routes, contactless delivery. Simplify your logistics and enhance the customer experience with Hedge.",
          },
          {
            title: "Marketing Tools",
            description:
              "Boost your revenue potential with Hedge's advanced loyalty and advertising features. Seamlessly connect all your social media accounts and unlock a host of additional features to enhance your online presence and engage with your customers like never before.",
          },
          {
            title: "Business App",
            description:
              "Introducing the Hedge Business Manager App - your on-the-go solution for managing your business effortlessly. With just a tap, you can track and oversee orders, manage deliveries, and monitor your business seamlessly. Stay in control and keep your business running smoothly no matter where you are.",
          },
        ]}
      />
      <Box className={classes.startSimple}>
        <Container>
          <Box className={classes.startSimpleTitle}>
            <Typography variant="h2">The fine print</Typography>
          </Box>
          <Grid container spacing={12}>
            <Grid item sm={12} md={6} lg={6} className={classes.stepBox}>
              <Box className={classes.stepContainer}>
                <Box className={classes.stepperBox}>
                  <Box className={classes.stepperImage}>
                    <img src={rectangle} alt="rectangle" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Commission</Typography>
                    <Typography>
                      Partners pay Hedge a fee based on all orders transacted
                      through the Hedge marketplace platform.
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperBox}>
                  <Box className={classes.stepperImage}>
                    <img src={rectangle} alt="rectangle" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Delivery</Typography>
                    <Typography>
                      Partners pay Hedge a fee based on deliveries processed
                      through the Hedge delivery app. Any tips left by the
                      customer will 100% be passed to the Driver.
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperBox}>
                  <Box className={classes.hedgeLogo}>
                    <img src={ap} alt="ap" className={classes.hLogo} />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Payment</Typography>
                    <Typography>
                      For orders processed via the Hedge marketplace platform.
                      Hedge will remit payment to your nominated bank account
                      every 7 days, less any commissions, delivery or
                      subscription fees applicable.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <Box>
                <img
                  src={fineprint}
                  alt="fineprintImage"
                  className={classes.sellingImage}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box marginTop={"50px"} marginBottom={"50px"}>
        <CommissionSection />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "80px",
        }}
      >
        <a href="https://www.hedge.net.au/become-a-stockist" target="_blank" rel="noopener noreferrer">
          <Button className={classes.ChoosePlan}>Become A Stockist</Button>
        </a>
      </Box>
      <ReusableFAQs frequentlyAskQuestions={frequentlyAskQuestions} />
      <Footer />
    </>
  );
}

export default PricingPage;
