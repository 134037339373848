import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { useMutation, gql } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import useStyles from "./styles";
import {
  verifyOtp,
  sendOtpToVendorEmail,
  createVendor,
} from "../../apollo/server";
import { sendEmail } from "../../utils/emailService";
import CardImage from "../../assets/images/easy-eats.png";
import { GridLoader } from "react-spinners";

const VERIFY_OTP = gql`
  ${verifyOtp}
`;

const SEND_OTP_TO_VENDOR_EMAIL = gql`
  ${sendOtpToVendorEmail}
`;

const CREATE_VENDOR = gql`
  ${createVendor}
`;

const OtpVerificationScreen = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { formData } = location.state || {};
  console.log("🚀 ~ OtpVerificationScreen ~ formData:", formData)
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resendTimer, setResendTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [verifyOtp] = useMutation(VERIFY_OTP);
  const [sendOtp, { loading: sendLoading }] = useMutation(
    SEND_OTP_TO_VENDOR_EMAIL
  );
  const [createVendorMutation] = useMutation(CREATE_VENDOR);

  useEffect(() => {
    startCountdown();
    // Display toast message when component mounts
    setAlert({
      open: true,
      severity: "info",
      message: "OTP has been sent to your email.",
    });
  }, []);

  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  const startCountdown = () => {
    setResendTimer(30);
    setIsResendDisabled(true);
  };

  const handleChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    const otpString = otp.join("");

    if (!formData || !formData.email) {
      setAlert({
        open: true,
        severity: "error",
        message: "Email not found. Please go back and fill the form again.",
      });
      setIsLoading(false); // Stop loading
      return;
    }

    try {
      const { data } = await verifyOtp({
        variables: { email: formData.email, otp: otpString },
      });

      if (data.verifyOtp.result) {
        const fullName = `${formData.firstName} ${formData.lastName}`.trim();
        // OTP verified successfully, now create vendor
        try {
          const vendorInput = {
            email: formData.email,
            password: formData.password,
            name : fullName
          };

          const { data: vendorData } = await createVendorMutation({
            variables: { vendorInput },
          });

          if (vendorData && vendorData.createVendor) {
            console.log(
              "Vendor created successfully:",
              vendorData.createVendor
            );

            // Now send the email
            const templateParams = {
              ...formData,
              role: "Business Listing",
              isRider: false,
            };

            const response = await sendEmail(
              "template_ufhqmzs",
              templateParams
            );
            console.log(
              "Email sent successfully!",
              response.status,
              response.text
            );

            setAlert({
              open: true,
              severity: "success",
              message: "Account Created Successfully!",
            });

            // Navigate to the next step or redirect
            // For example: navigate('/PaymentPage', { state: { planData: formData.plan } });
            navigate("/SellerEmailConfirmation");
          } else {
            throw new Error("Failed to create vendor");
          }
        } catch (vendorError) {
          console.error("Failed to create vendor:", vendorError);
          setAlert({
            open: true,
            severity: "error",
            message:
              "OTP verified, but failed to create account. Please try again.",
          });
          setIsLoading(false);
        }
      } else {
        setAlert({
          open: true,
          severity: "error",
          message: "Invalid OTP. Please try again.",
        });
        setIsLoading(false);
      }
    } catch (error) {
      setAlert({
        open: true,
        severity: "error",
        message: "Error verifying OTP: " + error.message,
      });
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    if (!formData || !formData.email) {
      setAlert({
        open: true,
        severity: "error",
        message: "Email not found. Please go back and fill the form again.",
      });
      return;
    }

    try {
      const generatedOtp = Math.floor(
        100000 + Math.random() * 900000
      ).toString();
      const { data } = await sendOtp({
        variables: { email: formData.email, otp: generatedOtp },
      });

      if (data.sendOtpToVendorEmail.result) {
        setAlert({
          open: true,
          severity: "success",
          message: "OTP has been sent again. Please check your email.",
        });
        startCountdown();
      } else {
        setAlert({
          open: true,
          severity: "error",
          message: "Failed to resend OTP. Please try again.",
        });
      }
    } catch (error) {
      setAlert({
        open: true,
        severity: "error",
        message: "Error resending OTP: " + error.message,
      });
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  return (
    <Box className={classes.fullScreenContainer}>
      <Box className={classes.styledCard}>
        <Box className={classes.imagePlaceholder}>
          <Typography variant="body2" color="textSecondary">
            <img src={CardImage} alt={"otpscreen"} width={200} height={200} />
          </Typography>
        </Box>
        <Typography component="h1" variant="h4" className={classes.heading}>
          Enter Verification Code
        </Typography>
        <Typography
          component="h4"
          variant="subtitle1"
          className={classes.subheading}
        >
          Please enter the OTP code that has been sent to {formData.email}
        </Typography>
        <form onSubmit={handleSubmit} className={classes.styledForm}>
          <Box className={classes.otpInput}>
            {otp.map((digit, index) => (
              <TextField
                key={index}
                id={`otp-${index}`}
                variant="outlined"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                className={classes.otpDigit}
              />
            ))}
          </Box>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={otp.some((digit) => digit === "") || isLoading}
            className={classes.styledButton}
          >
            {isLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <GridLoader
                  color="#000000"
                  loading={true}
                  size={8}
                />
              </Box>
            ) : (
              "Verify"
            )}
          </Button>
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={handleResend}
              disabled={isResendDisabled || sendLoading}
              className={classes.resendButton}
            >
              {isResendDisabled
                ? `Resend in ${resendTimer}s`
                : sendLoading
                ? "Sending..."
                : "Resend Code"}
            </Button>
          </Box>
        </form>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OtpVerificationScreen;
