import { makeStyles } from "@mui/styles";
import Delivery_banner from '../../../src/assets/images/Delivery_banner.png'
import step_line from '../../../src/assets/images/stepper-Line 1.png'

const useStyle = makeStyles(theme => ({
    heroSection: {
        backgroundImage: `url(${Delivery_banner})`,
        height: '350px',
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        marginTop: '64px',
    },
    gaping: {
        margin: '75px 0 ',
        [theme.breakpoints.down("sm")]: {
            margin: '30px 0 ',
            padding:'0 15px'
          },
    },
    heroTextBanner: {
        padding: '130px 0',
        [theme.breakpoints.down("sm")]: {
            padding: '115px 0',
          },
        "& h2": {
            fontSize: '54px',
            fontWeight: '600 ',
            color: '#fff',
            [theme.breakpoints.down("sm")]: {
                fontSize: '40px',
                marginBottom:'10px',
              },
        },
        "& p": {
            color: '#fff'
        }
    },
    readyTextBox: {
        marginTop: '20px',
        "& h3": {
            fontSize: '30px',
            color: '#0D0D0D',
            fontWeight: '600',
            [theme.breakpoints.down("sm")]: {
                fontSize: '20px',
              },
        },
        "& p": {
            fontSize: '16px',
            fontWeight: '400',
            color: '#757575',
            maxWidth: '330px',
            marginTop: '8px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '13px',
                maxWidth: '260px',
              },
        }
    },
    download_btn: {
        display: 'flex',
        gap: '8px',
        marginTop: '50px',
        [theme.breakpoints.down("sm")]: {
          flexWrap:'wrap',
        },
        "& p": {
            fontSize: '14px',
            color: '#0065DC',
            textAlign: 'center',
            fontWeight: '400',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    steppers: {
        backgroundColor: '#F9F9F9',
        padding: '50px',
        [theme.breakpoints.down("sm")]: {
            padding:'20px'
        },
    },
    reusablesteppers: {
        backgroundColor: '#F9F9F9',
        padding: '10px',
        [theme.breakpoints.down("sm")]: {
            padding:'20px'
        },
    },
    howWork_step_box: {
        width: "70px",
        height: "70px",
        borderRadius: "100px",
        border: "1px solid #333",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "15px",
        [theme.breakpoints.down("sm")]: {
            margin: '0 auto'
        },
    },
    howWork_title: {
        marginTop: '50px',
        marginBottom: "30px",
        "& h2": {
            fontSize: "30px",
            fontWeight: "600",
            color: "#1e1a18",
            [theme.breakpoints.down("sm")]: {
                fontSize: '20px',
            },
        },
        "& p": {
            fontWeight: "500",
            color: "#787878",
            marginBottom: "10px",
        },
    },
    howWork_section: {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        marginBottom: "70px",
        [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
            gap: "0px",
        },
    },
    howWork_step: {
        width: "70px",
        height: "70px",
        borderRadius: "100px",
        border: "1px solid #000",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "15px",
        "& svg": {
            fontSize: "35px",
        },
        "& h2": {
            color: "#0D0D0D",
            fontSize: "32px",
            fontWeight: "200",
        },
    },
    howWork_step2: {
        "& h6": {
            color: "#4B4B4B",
            fontSize: "24px",
            fontWeight: "500",
        },
        "& p": {
            color: "#757575",
            fontSize: "14px",
        },
    },
    last_step: {
        position: 'relative',
        [theme.breakpoints.down("md")]: {
            marginBottom: "15px",
        },
        "& h6": {
            color: "#4B4B4B",
            fontSize: "24px",
            fontWeight: "500",
        },
        "& p": {
            color: "#666",
            fontSize: "14px",
        },
    },
    startSimpleTitle:{
        padding:'75px 0',
        "& h2":{
            fontSize:'30px',
            fontWeight:'600',
            color:'#0D0D0D',
        }
    },
    stepperBox:{
        display:'flex',
        alignItems:'center',
        gap:'30px',
        marginTop:'40px'
    },
    stepperText:{
        "& h3":{
            fontSize:'20px',
            fontWeight:'600',
            color: theme.palette.common.bgColor,
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
              },
        },
        "& p":{
            fontSize:'14px',
            fontWeight:'400',
            color: theme.palette.grey["600"],
            [theme.breakpoints.down("sm")]: {
                fontSize: "13px",
              },
        }
    },
    stepperBtn:{
        textAlign:'end',
        "& .MuiButton-root":{
            backgroundColor: theme.palette.common.bgColor,
            fontSize:'14px',
            fontWeight:'400',
            color:theme.palette.primary.light,
            marginTop:'20px',
            textTransform:'capitalize',
            '&:hover':{
                backgroundColor: theme.palette.common.bgLight,
            }
        }
      },
      stepBox:{
        paddingLeft:'40px !important',
        position:'relative',
        "&:before":{
            content:'""',
            position:'absolute',
            backgroundImage:`url(${step_line})`,
            width:'1px',
            height:'150px',
            backgroundRepeat:"no-repeat",
            backgroundSize:'cover',
            top:'90px',
            zIndex:'-1',
            left:'56px',
            [theme.breakpoints.down("xs")]: {
                    height:'180px'
            },
        }
      },
      readyimage:{
        "& img":{
            width:'100%',
            height:'100%',
        }
      },
      simpleImage:{
        "& img":{
            width:'100%'
        }
      },
      stepperImage:{
        "& img":{
            width:'35px',
            height:'35px',
            [theme.breakpoints.down("sm")]: {
                width:'30px',
                height:'30px',
                objectFit:'cover'
            },
        }
      },
      hedgeLogo:{
        "& img":{
            width:'45px',
            height:'45px',
            position:'relative',
            left:'-6px',
            [theme.breakpoints.down("sm")]: {
                width:'30px',
                height:'30px',
                objectFit:'cover',
                left:'2px'
            },
        }
      },
      accordionTitle:{
        fontSize:'30px',
        fontWeight:'500',
        color: theme.palette.common.bgColor,
        marginBottom:'20px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
          },
    }
}))

export default useStyle;